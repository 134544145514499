

var msnry, flick;


$(function() {

  //Fastclick
  FastClick.attach(document.body);

  //Masonry init
  initMasonry();
  // initLazyLoading();


  //Flickity init
  initFlickity();

  // Show more for weddings
  bindShowMore();

});

function initMasonry($container) {
  var $container = $('.photos.masonry');
  
  if ($container.length > 0) {
    imagesLoaded($container, function() {
      $container.addClass('loaded');
      $('.more-photos').removeClass('hidden');


      initPhotoswipe();

   //   initialize Masonry
      msnry = new Masonry($container[0], {
        isAnimated: false,
        gutter: '.gutter-sizer',
        itemSelector: '.photo',
        isFitWidth: true,
        transitionDuration: 0
     });

      // Lazy load the images
      $container.find('img').unveil(300, function() {
        $(this).load(function() {
          $(this).removeClass('lazy');
          msnry.layout();
        });
      });
    });
  }
}

function initLazyLoading($container) {
  var $container = $('.photos.flgflex');
  
  if ($container.length > 0) {
    imagesLoaded($container, function() {
      $container.addClass('loaded');
      $('.more-photos').removeClass('hidden');


      initPhotoswipe();


      // Lazy load the images
      $container.find('img').unveil(300, function() {
        $(this).load(function() {
          $(this).removeClass('lazy');
        });
      });
    });
  }
}



function initFlickity() {
  var $container = $('.photos.flickity');
  if ($container.length > 0) {


    // var draggable = Modernizr.mq('(min-width: 700px)');
    imagesLoaded($container, function() {
      $container.addClass('loaded');


      
      flick = $container.flickity({
        autoPlay: 5000,
        cellAlign: 'center',
        cellSelector: '.photo',
        wrapAround: true,
        // friction: .3,
        draggable: false,
        // freeScroll: true,
        pageDots: false,
        // touchVerticalScroll: false,
        // prevNextButtons: !draggable
      });

      

      initPhotoswipeForFlickity();
    });

  }
}



function initPhotoswipeForFlickity() {
  var initPhotoSwipeFromDOM = function(gallerySelector) {




    // parse slide data (url, title, size ...) from DOM elements 
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
      var thumbElements = el.childNodes,
        numNodes = thumbElements.length,
        items = [],
        figureEl,
        linkEl,
        size,
        item;

      for (var i = 0; i < numNodes; i++) {

        figureEl = thumbElements[i]; // <figure> element

        // include only element nodes 
        if (figureEl.nodeType !== 1) {
          continue;
        }

        linkEl = figureEl.children[0]; // <a> element
        size = [];

        size[0] = linkEl.getAttribute('data-img-width');
        size[1] = linkEl.getAttribute('data-img-height');

        // create slide object
        item = {
          src: linkEl.getAttribute('href'),
          w: parseInt(size[0], 10),
          h: parseInt(size[1], 10)
        };



        if (figureEl.children.length > 1) {
          // <figcaption> content
          item.title = figureEl.children[1].innerHTML;
        }

        if (linkEl.children.length > 0) {
          // <img> thumbnail element, retrieving thumbnail url
          item.msrc = linkEl.children[0].getAttribute('src');
        }

        item.el = figureEl; // save link to element for getThumbBoundsFn
        items.push(item);
      }

      return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
      return el && (fn(el) ? el : closest(el.parentNode, fn));
    };

   
    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
      var hash = window.location.hash.substring(1),
        params = {};

      if (hash.length < 5) {
        return params;
      }

      var vars = hash.split('&');
      for (var i = 0; i < vars.length; i++) {
        if (!vars[i]) {
          continue;
        }
        var pair = vars[i].split('=');
        if (pair.length < 2) {
          continue;
        }
        params[pair[0]] = pair[1];
      }

      if (params.gid) {
        params.gid = parseInt(params.gid, 10);
      }

      if (!params.hasOwnProperty('pid')) {
        return params;
      }
      params.pid = parseInt(params.pid, 10);
      return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation) {
      var pswpElement = document.querySelectorAll('.pswp')[0],
        gallery,
        options,
        items;

      items = parseThumbnailElements(galleryElement);

      // define options (if needed)
      options = {
        index: index,

        // define gallery index (for URL)
        galleryUID: galleryElement.getAttribute('data-pswp-uid'),
       

        getThumbBoundsFn: function(index) {
          // See Options -> getThumbBoundsFn section of documentation for more info
          var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
            pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
            rect = thumbnail.getBoundingClientRect();

          return {
            x: rect.left,
            y: rect.top + pageYScroll,
            w: rect.width
          };
        }

      };

      if (disableAnimation) {
        options.showAnimationDuration = 0;
      }

      // Pass data to PhotoSwipe and initialize it
      gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
      gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll(gallerySelector);

    for (var i = 0, l = galleryElements.length; i < l; i++) {
      galleryElements[i].setAttribute('data-pswp-uid', i + 1);
    }

    $(''+gallerySelector+' .photo a').on('click', function(e){
      e.preventDefault();

      var $photos = $('.photos.flickity .photo');
      var $current = $(this).parents('.photo');
      var index = jQuery.inArray($current[0], $photos);
      console.log("Index:" +index);
      openPhotoSwipe(index, $(''+gallerySelector)[0]);
      return false;
    });
    

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if (hashData.pid > 0 && hashData.gid > 0) {
      openPhotoSwipe(hashData.pid - 1, galleryElements[hashData.gid - 1], true);
    }
  };

  // execute above function
  initPhotoSwipeFromDOM('.photos.flickity .flickity-slider');

}

function initPhotoswipe() {
  var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements 
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
      var thumbElements = el.getElementsByClassName('photo'),
        numNodes = thumbElements.length,
        items = [],
        figureEl,
        linkEl,
        size,
        item;

      for (var i = 0; i < numNodes; i++) {

        figureEl = thumbElements[i]; // <figure> element

        // include only element nodes 
        if (figureEl.nodeType !== 1) {
          continue;
        }

        linkEl = figureEl.children[0]; // <a> element

        size = [];

        size[0] = linkEl.getAttribute('data-img-width');
        size[1] = linkEl.getAttribute('data-img-height');

        // create slide object
        item = {
          src: linkEl.getAttribute('href'),
          w: parseInt(size[0], 10),
          h: parseInt(size[1], 10)
        };



        if (figureEl.children.length > 1) {
          // <figcaption> content
          item.title = figureEl.children[1].innerHTML;
        }

        if (linkEl.children.length > 0) {
          // <img> thumbnail element, retrieving thumbnail url
        //  item.msrc = linkEl.children[0].getAttribute('src');
        }

        item.el = figureEl; // save link to element for getThumbBoundsFn
        items.push(item);
      }

      return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
      return el && (fn(el) ? el : closest(el.parentNode, fn));
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
      e = e || window.event;
      e.preventDefault ? e.preventDefault() : e.returnValue = false;

      var eTarget = e.target || e.srcElement;

      // find root element of slide
      var clickedListItem = closest(eTarget, function(el) {
        return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
      });

      if (!clickedListItem) {
        return;
      }

      // find index of clicked item by looping through all child nodes
      // alternatively, you may define index via data- attribute
      var clickedGallery = clickedListItem.parentNode,
        childNodes = clickedListItem.parentNode.childNodes,
        numChildNodes = childNodes.length,
        nodeIndex = 0,
        index;

      for (var i = 0; i < numChildNodes; i++) {
        if (childNodes[i].nodeType !== 1) {
          continue;
        }

        if (childNodes[i] === clickedListItem) {
          index = nodeIndex-1;
          break;
        }
        nodeIndex++;
      }



      if (index >= 0) {
        // open PhotoSwipe if valid index found
        openPhotoSwipe(index, clickedGallery);
      }
      return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
      var hash = window.location.hash.substring(1),
        params = {};

      if (hash.length < 5) {
        return params;
      }

      var vars = hash.split('&');
      for (var i = 0; i < vars.length; i++) {
        if (!vars[i]) {
          continue;
        }
        var pair = vars[i].split('=');
        if (pair.length < 2) {
          continue;
        }
        params[pair[0]] = pair[1];
      }

      if (params.gid) {
        params.gid = parseInt(params.gid, 10);
      }

      if (!params.hasOwnProperty('pid')) {
        return params;
      }
      params.pid = parseInt(params.pid, 10);
      return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation) {
      var pswpElement = document.querySelectorAll('.pswp')[0],
        gallery,
        options,
        items;

      items = parseThumbnailElements(galleryElement);

      // define options (if needed)
      options = {
        index: index,

        // define gallery index (for URL)
        galleryUID: galleryElement.getAttribute('data-pswp-uid'),

        // showAnimationDuration: 10,
        // showHideOpacity: true,
        // getThumbBoundsFn: false,
        getThumbBoundsFn: function(index) {
          // See Options -> getThumbBoundsFn section of documentation for more info
          var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
            pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
            rect = thumbnail.getBoundingClientRect();

          return {
            x: rect.left,
            y: rect.top + pageYScroll,
            w: rect.width
          };
        }

      };

      if (disableAnimation) {
        options.showAnimationDuration = 0;
      }

      // Pass data to PhotoSwipe and initialize it
      gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
      gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll(gallerySelector);

    for (var i = 0, l = galleryElements.length; i < l; i++) {
      galleryElements[i].setAttribute('data-pswp-uid', i + 1);
      galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if (hashData.pid > 0 && hashData.gid > 0) {
      openPhotoSwipe(hashData.pid - 1, galleryElements[hashData.gid - 1], true);
    }
  };

  // execute above function
  initPhotoSwipeFromDOM('.photos');
}


function bindShowMore() {

  $('#show-more-testimonials').on('click', function(e) {
    e.preventDefault();
    $('.testimonials').addClass('refilled');
    $(this).addClass('hidden');
  })
}
